import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzTableModule } from 'ng-zorro-antd/table';
import { ManageTaskService } from "../../services/manage-tasks.service";
import { Subscription } from "rxjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzTagModule } from 'ng-zorro-antd/tag';
import { InChargeHistory } from "@wearewarp/types/data-model/types/WorkTask";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { RouterModule } from "@angular/router";
import { BaseWorkQueueTabList } from "../base";


const CustomInputs = [
  CommonModule,
  RouterModule,
  FormsModule,
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzTableModule,
  NzSelectModule,
  NzTagModule,
  NzToolTipModule,
]

@Component({
  selector: '[escalation-tab-list]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class EscalationTabList extends BaseWorkQueueTabList {
  private manageServiceSubscription: Subscription = new Subscription();

  displayInfo: any = {};

  constructor(private manageTaskService: ManageTaskService){
    super();
  }

  ngOnInit() {
    this.manageServiceSubscription.add(this.manageTaskService.paginationData.subscribe((value) => {
      if (value) {
        this.setPaginationData(value);
        this.buildDisplayInfo();  
      }
    }))
  }

  ngOnDestroy(): void {
    this.manageServiceSubscription?.unsubscribe();
  }

  async buildDisplayInfo() {
    this.displayInfo['listData'] = await Promise.all(this.listData?.map(async item => {
      return {
        ...item,
        taskName: this.getTaskName(item),
        // jobInfo
        jobCode: item.jobInfo?.code || 'N/A',
        stopDesc: this.getStopDesc(item) || '',
        escalationNote: this.getEscalationNote(item),
        escalationFullNote: this.getFullAnswerNote(item)
      }
    }))
  }

  getLastInchargeHistory(item): InChargeHistory | undefined {
    let inChargeHistories: InChargeHistory[] = item?.inChargeHistories || [];
    let lastInCharge = inChargeHistories[inChargeHistories.length - 1];
    return lastInCharge || undefined;
  }

  getEscalationNote(item) {
    let lastInCharge = this.getLastInchargeHistory(item);
    let answerType = lastInCharge.answerType
    if (answerType) {
      return Const.getTaskAnswerText(answerType);
    }
    return 'N/A';
  }

  getFullAnswerNote(item) {
    let lastInCharge = this.getLastInchargeHistory(item);
    let desc = '';
    if (lastInCharge?.answerType) {
      desc += `${Const.getTaskAnswerText(lastInCharge.answerType)}\n`;
    }
    if (lastInCharge?.data) {
      for (let key in lastInCharge.data || {}) {
        desc += `${key}: ${lastInCharge.data[key]}\n`;
      }
    }
    return desc;
  }

  routeToAdminDispatch(item) {
    const jobId = item?.object?.entityId || '';
    return Const.routeAdminDispatch(jobId);
  }

  routeToWorkEscalatatedTask(item) {
    const taskId = item?.id || '';
    if (!taskId) return '';
    return Const.routeWorkSpecificTask(taskId);
  }
}