import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzTableModule } from 'ng-zorro-antd/table';
import { ManageTaskService } from "../../services/manage-tasks.service";
import { Subscription } from "rxjs";
import { NzIconModule } from "ng-zorro-antd/icon";
import { GetWhenByService } from "@services/getWhenBy.service";
import { IByData } from "@app/interfaces";
import { NzSelectModule } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { DialogService } from "@app/dialogs/dialog.service";
import { RemoveWorkTaskDialog } from "../components/remove-task-dialog";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { DateUtil } from "@services/date-util";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { BaseWorkQueueTabList } from "../base";

const CustomInputs = [
  CommonModule,
  FormsModule,
  NgIf, NgFor, NgClass,
  NzIconModule,
  NzTableModule,
  NzSelectModule,
  NzButtonModule,
  NzToolTipModule,
  RemoveWorkTaskDialog
]

@Component({
  selector: '[paused-tab-list]',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class PausedTabList extends BaseWorkQueueTabList {
  private manageServiceSubscription: Subscription = new Subscription();

  displayInfo: any = {};
  listOperators = [];
  listOperatorIds = [];
  private timer;

  constructor(private manageTaskService: ManageTaskService, private getWhenByService: GetWhenByService){
    super();
  }

  ngOnInit() {
    this.manageServiceSubscription.add(this.manageTaskService.paginationData.subscribe((value) => {
      if (value) {
        this.setPaginationData(value);
        this.buildDisplayInfo();  
      }
    }))
    this.manageTaskService.getListOperators().subscribe((operators) => {
      this.listOperators = operators || [];
      this.listOperatorIds = this.listOperators?.map(it => it.id);
    })
    this.timer = setInterval(() => {
      this.reloadTimeRemaining();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.manageServiceSubscription?.unsubscribe();
    clearInterval(this.timer);
  }

  async buildDisplayInfo() {
    this.displayInfo['listData'] = await Promise.all(this.listData?.map(async item => {
      let byId = item?.inChargeId?.split('_')?.[1] || '';
      let inChargeInfo = byId ? await this.getWhenByData({ byId }) : null;
      return {
        ...item,
        status: item.status,
        taskName: this.getTaskName(item),
        statusText: this.getTaskStatus(item),
        assignee: inChargeInfo ? this.getAssigneeFullName(inChargeInfo) : '',
        inChargeId: byId,
        pauseWarning: this.getPauseWarning(item),
        timeRemaining: this.getTimeRemaining(item),
        // jobInfo
        jobCode: item.jobInfo?.code || 'N/A',
        stopDesc: this.getStopDesc(item) || '',
      }
    }))
  }

  getAssigneeFullName(inChargeInfo) {
    if (!inChargeInfo) return '';
    return this.getWhenByService.getFullName(inChargeInfo);
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }

  isAssigned(item) {
    return item?.inChargeId;
  }

  async onChangeTaskAssignee(item) {
    if (item?.inChargeId) {
      await this.manageTaskService.assignTaskToUser(item.id, item.inChargeId);
      this.reload.emit();
    }
  }

  getPauseWarning(item) {
    let inChargeHistories = item?.inChargeHistories || [];
    let index = inChargeHistories.length - 1;
    if (index < 0) return '';
    let history = inChargeHistories[index];
    if (!history.pauseTime) return '';
    return `Paused at ${this.$formatDate(history.pauseTime)} (EST).\nReason: ${history.data?.reason || 'N/A'}`;
  }

  getTimeRemaining(item) {
    if (!item?.timelines?.pauseTimeEnd) return '';
    let ms = DateUtil.getTimeDurationFromWindow({
      from: new Date().toISOString(),
      to: item.timelines.pauseTimeEnd
    })
    if (ms <= 0) return '0:00';
    let allSecs = Math.floor(ms / 1000);
    let mins = Math.floor(allSecs / 60); 
    return `${mins}:${allSecs % 60} s`;
  }

  // EST
  $formatDate = (date: Date) => {
    return DateUtil.displayLocalTime(date, 'America/New_York', 'MM/DD/YYYY hh:mm A');
  }

  isAssignedNotOperator(item) {
    return item?.inChargeId && !this.listOperatorIds.includes(item.inChargeId);
  }

  onBtnDeleteTask(item) {
    DialogService.openFormDialog(RemoveWorkTaskDialog, {
      nzData: {
        taskId: item.id,
        jobId: item.object?.entityId,
        closeOnSuccess: true,
        updateSuccess: () => {
          this.reload.emit();
        }
      },
      nzMaskClosable: true,
      nzClassName: 'modal-no-padding',
      nzWidth: '460px'
    });
  }

  onBtnResumeTask(item) {
    UIHelper.confirm(`Are you sure you want to resume this task?`, async () => {
      this.resumeTask(item.id);
    })
  }

  private resumeTask(taskId) {
    let url = Const.API_RESUME_TASK(taskId);
    this.api.POST(url, {}).subscribe({
      next: (resp) => {
        UIHelper.showSuccess('Resume successfully');
        this.reload.emit();
      },
      error: (err) => {
        UIHelper.showErr(err);
      }
    })
  }

  private reloadTimeRemaining() {
    for (let item of this.displayInfo.listData || []) {
      item.timeRemaining = this.getTimeRemaining(item);
      if (this.checkPauseTimeHasEnded(item)) {
        this.resumeTask(item.id);
        break;  // khi resume 1 task thì không cần check tiếp, tránh duplicate loading
      }
    }
  }

  private checkPauseTimeHasEnded(task): boolean {
    if (!task?.timelines?.pauseTimeEnd) return false;
    let ms = DateUtil.getTimeDurationFromWindow({
      from: new Date().toISOString(),
      to: task.timelines.pauseTimeEnd
    })
    return ms <= 0;
  }
  
}