import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseFormItem } from "@app/base-components/form-item";
import { Const } from "@const/Const";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { ManageTaskService } from "../services/manage-tasks.service";

const CustomInputs = [
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NgIf, NgFor, NgClass,
  NzTableModule,
  NzInputModule,
  NzSelectModule,
  NzButtonModule,
  NzIconModule,
]

@Component({
  selector: '[manage-task-header-filter]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class ManageTaskHeaderFilter extends BaseFormItem {
  @Input() tabIndex: number = Const.ManageTaskTabIndex.workqueue;
  @Output() filterChange = new EventEmitter<any>();
  @Output() resetFilter = new EventEmitter<any>();
  
  protected formGroupDeclaration: FormGroupDeclaration = {
    searchKeyword: { label: '', placeHolder: 'Search by Route, Shipment, REF, Customer', notAcceptEmpty: true },
    status: { label: '', placeHolder: 'Select Task Status'},
    type: { label: '', placeHolder: 'Select Task Type'},
    clientIds: { label: '', placeHolder: 'Select Customer'},
    inchargeIds: { label: '', placeHolder: 'Select Assignee'},
    shipmentType: { label: '', placeHolder: 'Select Shipment Type'},
  }

  listOperators: any = [];
  listClients: any = [];

  listFilterStatus = [
    { label: 'Open', value: [Const.WorkTaskStatus.pending] },
    { label: 'Working', value: [Const.WorkTaskStatus.inProcess] },
    { label: 'On Hold', value: [Const.WorkTaskStatus.onHold] },
  ]

  listFilterTaskType = [
    { label: Const.TrackingTaskLabel.routeConfirmation, value: Const.TrackingTask.routeConfirmation },
    { label: Const.TrackingTaskLabel.etaConfirmation, value: Const.TrackingTask.etaConfirmation },
  ]

  listFilterShipmentType = [
    { label: 'FTL', value: Const.ShipmentType.FTL },
    { label: 'LTL', value: Const.ShipmentType.LTL },
    { label: 'PARCEL', value: Const.ShipmentType.PARCEL },
  ]

  constructor(private manageTaskService: ManageTaskService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.manageTaskService.getListOperators().subscribe((operators) => {
      this.listOperators = (operators || []).map((user) => {
        return {
          label: user.fullName || `${user.firstName} ${user.lastName}`,
          value: user.id
        }
      });
    })
    this.manageTaskService.getListClients().subscribe((clients) => {
      this.listClients = (clients || []).map((client) => {
        return {
          label: client.name,
          value: client.id
        }
      });
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get shouldShowSearchBox() {
    return true;
  }

  get shouldShowFilterClients() {
    return [
      Const.ManageTaskTabIndex.workqueue,
      Const.ManageTaskTabIndex.paused,
    ].includes(this.tabIndex);
  }

  get shouldShowFilterTaskType() {
    return this.tabIndex === Const.ManageTaskTabIndex.workqueue;
  }

  get shouldShowFilterStatus() {
    return this.tabIndex === Const.ManageTaskTabIndex.workqueue;
  }

  get shouldShowFilterAssignee() {
    return [
      Const.ManageTaskTabIndex.workqueue,
      Const.ManageTaskTabIndex.paused,
    ].includes(this.tabIndex);
  }

  get shouldShowFilterShipmentType() {
    return [
      Const.ManageTaskTabIndex.workqueue,
      Const.ManageTaskTabIndex.paused,
    ].includes(this.tabIndex);
  }

  doSearch() {
    this.filterChange.emit();
  }

  onFilterChange() {
    this.filterChange.emit();
  }

  onBtnRefresh() {
    for(let key of this.formInputKeys || []) {
      this.setItemValue(key, null);
    }
    this.resetFilter.emit();
  }

  getData_JSON() {
    return this.getFormData_JSON(true);
  }
  
}