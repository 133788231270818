import { PermissionCode } from "@const/PermissionCode";

export const sideMenu: Array<SidebarMenuItem> = [
  {
    name: 'Manage Tasks',
    id: 'manage-tasks',
    link: 'manage-tasks',
    permissions: [PermissionCode.sidebar.manage_tasks],
    nzIcon: 'hdd',
  },
  {
    name: 'Operator Pools',
    id: 'operator-pools',
    link: 'operator-pools',
    permissions: [PermissionCode.sidebar.manage_tasks],
    nzIcon: 'control',
  }
];
