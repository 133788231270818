import { BaseComponent } from "@abstract/BaseComponent";
import { Directive, ViewChild } from "@angular/core";
import { PaginationData } from "@app/model/PaginationData";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { Log } from "@services/log";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { NzTableComponent } from 'ng-zorro-antd/table';


@Directive()
export abstract class BaseList<T = any> extends BaseComponent {
  apiVersion = 'v1';
  
  selectAll = false;
  selectedItems = [];

  public searchKeyword = '';
  public paginationData: PaginationData<T> = new PaginationData<T>();

  public isLastPage = false;
  get currentPage(): number { return Utils.toNumber((this.queryParams || {}).page, 1); }
  get searchFromQuery(): string { return (this.queryParams || {}).search || '' }
  get listData(): Array<T> { return this.paginationData ? this.paginationData.list_data : [] }
  get totalCount(): number { return this.paginationData ? this.paginationData.total : 0 }
  get skip(): number {
    return this.limit < 0 ? 0 : (this.currentPage - 1) * this.limit;
  }
  get currentPageSize(): number { return Utils.toNumber((this.queryParams || {}).limit, Const.PAGINATION_LIMIT); }
  get limit(): number { return this.currentPageSize; }
  pageCount: number = 0;
  public pageIndex = 1; // for those who use nz-pagination without nz-table
  isLoading = false;

  @ViewChild('nzTable', {static: false}) nzTable: NzTableComponent<any>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected needReload(prevQueryParam) {
    return this.listData.length == 0 || !Utils.compareObject(this.queryParams, prevQueryParam);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    this.pageIndex = Utils.toNumber(this.queryParams.page, 1);
    this.searchKeyword = this.queryParams.search || '';
    if (this.needReload(prevQueryParam)) {
      this.selectedItems = [];
      this.getData();
    }
  }

  protected abstract getApiUrl(): string;

  protected onGetDataSucceeded(resp) {
  }

  onBtnRefresh() {
    this.selectedItems = [];
    this.getData();
  }

  // use sort function of nz-table
  onSortOrderChange(event) {
    let query = Utils.cloneObject(this.queryParams);
    delete query.sort;
    switch (event.value) {
      case 'ascend':
        query.sort = `${event.key},1`;
        break;
      case 'descend':
        query.sort = `${event.key},-1`;
        break;
      default:
        break;
    }
    this.routeWithQueryUrl(query, true);
  }

  doSearch(keyword) {
    this.routeWithQueryUrl({page: 1, search: keyword});
  }

  onDataListPageChanged(page) {
    this.selectAll = false;
    this.routeWithQueryUrl({page: page});
  }
  onDataListPageSizeChanged(pageSize){
    this.selectAll = false;
    this.routeWithQueryUrl({limit: pageSize})
  }

  protected prepareParamGetList() {
    let params: any = {};
    params.skip = this.skip;
    params.limit = this.limit;
    if (this.queryParams.sort) {
      params.sort = this.queryParams.sort;
    }
    if (this.queryParams.search) {
      params.search = this.queryParams.search;
    }
    if (this.queryParams.filter) {
      params.filter = this.queryParams.filter;
    }
    return params;
  }

  protected getData() {
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      Log.e(`[${this.TAG}] getData but apiUrl is empty`);
      return;
    }
    let params = this.prepareParamGetList();
    let qs = new URLSearchParams(params).toString();
    if (apiUrl.indexOf('?') === -1) {
      apiUrl += '?';
    } else {
      apiUrl += '&';
    }
    apiUrl += qs;
    // mặc định gọi về BE workqueue
    this.getDataByUrl(this.api.buildUrl( apiUrl, this.apiVersion));
  }

  protected getDataByUrl(url: string) {
    this.isLoading = true;
    this.api.GET(url).subscribe({
      next: resp => {
        Log.d('get list data done: ', resp);
        this.getDataDone(resp);
        this.isLoading = false
      }, 
      error: err => {
        UIHelper.showErr(err);
        this.isLoading = false
      }
    });
  }

  protected getDataDone(resp) {
    this.paginationData.setData(resp.data);
    if (this.limit > 0) {
      this.pageCount = Math.ceil(this.totalCount / this.limit);
    } else {
      this.pageCount = 1;
    }
    let page = Utils.toNumber(this.queryParams.page, 1);
    if (this.nzTable && this.nzTable.nzPageIndex != page) {
      this.nzTable.nzPageIndex = page;
    }
    this.isLastPage = this.listData.length < this.limit || this.pageCount * this.limit === this.totalCount;
    this.onGetDataSucceeded(resp);
  }

  onBtnAdd() {
    let url = this.router.url.split('?')[0];
    this.router.navigate([url, 'add']);
  }
}