<div>
  <div class="summary-info">
    <div class="center-children">
      <span>Total: {{totalCount}}</span>
      <span *ngIf="getSelectedItems().length">
        <span style="color: #A1A1AA;" class="left10 right10">|</span>
        <i>Selected: {{ getSelectedItems().length }}</i>
      </span>
    </div>
    <div>
      <button nz-button nzType="default" nzSize="small" (click)="onBtnBulkAssign()" [disabled]="!canBulkAssign">Assign task</button>
    </div>
  </div>
  <nz-table
    #nzTable nzBordered="true"
    [nzData]="displayInfo.listData" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > listData.length"
    [nzPageIndex]="pageIndex"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    (nzPageIndexChange)="onDataListPageChanged($event)"
    [nzScroll]="{ x: '1200px' }"
    >
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th nzWidth="45px" nzLeft
          [nzChecked]="selectAll" [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllSelected($event)"
        ></th>
        <th>Task</th>
        <th nzWidth="220px" nzLeft>Route</th>
        <th nzWidth="220px" nzLeft>Customer</th>
        <th nzWidth="220px">Status</th>
        <th>Pool</th>
        <th nzWidth="220px">Assignee</th>
        <th nzWidth="180px">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of displayInfo.listData; let i = index">
        <td nzLeft (nzCheckedChange)="onItemSelected(item)" [nzChecked]="!!setOfSelectedId[item.id]" (click)="stopPropergate($event)"></td>
        <td nzLeft>{{ item?.taskName || 'N/A' }}</td>
        <!-- Route -->
        <td nzLeft>
          <div class="flex flex-space-between">
            <div><a [href]="routeToAdminDispatch(item)" target="_blank">{{ item.jobCode }}</a></div>
            <div class="text-secondary">{{ item.stopDesc }}</div>
          </div>
        </td>
        <!-- Customer -->
         <td>
          <div *ngIf="item.clientInfo?.name; else noCustomer">
            {{ item.clientInfo.name }}
          </div>
          <ng-template #noCustomer>
            <span class="text-secondary">N/A</span>
          </ng-template>
         </td>
        <!-- Status -->
        <td>
          <div class="flex flex-space-between">
            <div><span class="task-status" [ngClass]="item?.status">{{ item?.statusText || 'N/A' }}</span></div>
            <div *ngIf="isTaskWorking(item)">{{ item.workDuration}}</div>
          </div>
          <div *ngIf="item.readyAt" class="top5 text-secondary small-text">{{ item.readyAt }}</div>
        </td>
        <!-- Pool -->
        <td>
          <div>{{ item.poolName }}</div>
        </td>
        <!-- Assignee -->
        <td class="btn-assign">
          <ng-container *ngIf="!isAssignedNotOperator(item)">
            <nz-select style="width: 100%"
              nzShowSearch
              nzBackdrop="true"
              nzBorderless="true"
              [nzPlaceHolder]="'Assign'"
              [(ngModel)]="item.inChargeId"
              (ngModelChange)="onChangeTaskAssignee(item)"
            >
              <nz-option *ngFor="let user of listOperators" [nzValue]="user.id" [nzLabel]="getFullName(user)"></nz-option>
            </nz-select>
          </ng-container>
          <!-- Nếu admin làm task này thì vẫn show tên admin -->
          <ng-container *ngIf="isAssignedNotOperator(item)">
            <div class="assigned-not-operator">{{ item.assignee }}</div>
          </ng-container>
        </td>
        <!-- Delete task -->
        <td><div class="btn-delete" (click)="onBtnDeleteTask(item)">Delete task</div></td>
      </tr>
    </tbody>
  </nz-table>
</div>