import { Directive } from "@angular/core";
import { BaseTable } from "@app/base-components/base-table";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-util";
import { Utils } from "@services/utils";
import { Stop } from "@wearewarp/types/data-model";


@Directive()
export class BaseWorkQueueTabList<T=any> extends BaseTable<T> {

  protected getTaskName(item) {
    return Const.TrackingTaskLabel[item?.type] || '';
  }

  protected getTaskStatus(item) {
    return Const.WorkTaskStatusText(item?.status);
  }

  protected getTaskReadyAt(item) {
    let duration = 0;
    let startTime = item?.window?.from;
    let pauseTimeEnd = item?.timelines?.pauseTimeEnd;
    if (pauseTimeEnd) {
      duration = DateUtil.getTimeDurationFromWindow({
        from: startTime,
        to: pauseTimeEnd
      });
      startTime = duration > 0 ? pauseTimeEnd : startTime;
    }
    duration = DateUtil.getTimeDurationFromWindow({
      from: startTime,
      to: new Date().toISOString()
    });
    if (duration >=0 ) return '';
    return `Ready at ${DateUtil.formatDate(startTime, 'DD/MM h:mm A')}`;
  }

  protected getFullName(user) {
    return Utils.getFullName(user);
  }

  protected getStopDesc(item) {
    if (!item) return '';
    let stops: Stop[] = item?.jobInfo?.stops || [];
    let stopId = item?.object?.metadata?.stopId;
    let stop = stops?.find(it => it.id === stopId);
    if (!stopId || !stop) return '';
    let stopIndex = stops.findIndex(it => it.id === stopId) + 1;
    let stopType;
    if (stop.type === Const.RouteTaskType.PICKUP) stopType = 'PU';
    else if (stop.type === Const.RouteTaskType.DROPOFF) stopType = 'DO';
    return `Stop ${stopIndex} - ${stopType}`;
  }

  routeToAdminDispatch(item) {
    const jobId = item?.object?.entityId || '';
    return Const.routeAdminDispatch(jobId);
  }
}