import { Routes } from "@angular/router";
import { ManagerDashboardComponent } from "./dashboard";
import { PageError } from "@app/page-error/page-error";
import { ManageTaskList } from "./manage-tasks/list";
import { ManageOperatorPoolList } from "./operator-pools/list";

export const DASHBOARD_ROUTES: Routes = [
  {
    path: '', component: ManagerDashboardComponent, data: { title: 'Dashboard - WARP Work Manager' },
    children: [
      { path: 'manage-tasks', component: ManageTaskList, data: { title: 'Manage Task - Warp Work' } },
      { path: 'operator-pools', loadChildren: () => import('./operator-pools/operator-pools.routing').then(m => m.OPERATOR_POOLS_ROUTES) },
      { path: '**', component: PageError }
    ]
  }
]