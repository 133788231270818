<div class="dashboard-child-container bg-white">
  <div class="tabset-header">
    <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" (nzSelectedIndexChange)="onTabIndexChange()">
      <nz-tab [nzTitle]="'Escalations'"></nz-tab>
      <nz-tab [nzTitle]="'Work Queue'"></nz-tab>
      <nz-tab [nzTitle]="'Resolved'"></nz-tab>
      <nz-tab [nzTitle]="'Paused'"></nz-tab>
    </nz-tabset>
  </div>

  <div #headerFilter manage-task-header-filter [tabIndex]="selectedTabIndex" (filterChange)="onFilterChange()" (resetFilter)="onResetFilter()" class="header-filter"></div>

  <div class="list-body">
    <ng-container [ngSwitch]="selectedTabIndex">
      <ng-container *ngSwitchCase="0">
        <div escalation-tab-list [isLoading]="isLoading" (onPageIndexChange)="onDataListPageChanged($event)"></div>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <div work-queue-tab-list [isLoading]="isLoading" (onPageIndexChange)="onDataListPageChanged($event)" (reload)="reload()"></div>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <div resolved-tab-list [isLoading]="isLoading" (onPageIndexChange)="onDataListPageChanged($event)"></div>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <div paused-tab-list [isLoading]="isLoading" (onPageIndexChange)="onDataListPageChanged($event)" (reload)="reload()"></div>
      </ng-container>
    </ng-container>
  </div>

</div>