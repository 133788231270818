import { PaginationDataInterface } from "@app/interfaces";
import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { UIHelper } from "@services/UIHelper";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { Client, User } from "@wearewarp/types/data-model";
import to from 'await-to-js';

@Injectable()
export class ManageTaskService {
  public tabIndex: BehaviorSubject<number> = new BehaviorSubject(null);
  public paginationData: BehaviorSubject<PaginationDataInterface<any> > = new BehaviorSubject<PaginationDataInterface<any> >(null);
  public listOperators: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(null);
  public listClients: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>(null);
  public fetchingOperators = new BehaviorSubject<boolean>(false);
  public fetchingClients = new BehaviorSubject<boolean>(false);
  
  constructor(private api: ApiService) {
  }

  public setPaginationData(data) {
    let pagination: PaginationDataInterface<any> = {
      skip: data?.skip || 0,
      total: data?.total || 0,
      limit: data?.limit || Const.PAGINATION_LIMIT,
      list_data: data?.list_data || [],
      count: data?.list_data?.length || 0,
      pageIndex: data?.pageIndex || 1
    }
    this.paginationData.next(pagination);
  }

  public resetPaginationData() {
    this.paginationData.next({
      skip: 0, total: 0, limit: Const.PAGINATION_LIMIT,
      list_data: [], count: 0, pageIndex: 1
    })
  }

  getListOperators(): Observable<User[] | null> {
    if (this.fetchingOperators.value) {
      return this.listOperators.asObservable();
    }
    if (this.listOperators.value) {
      return this.listOperators.asObservable();
    }

    this.fetchingOperators.next(true);
    let url = `${environment.backendUrl}/${Const.APIURI_LIST_OPERATORS}`;
    const res = this.api.GET(url);
    res.subscribe(response => {
      this.listOperators.next(response.data?.list_data as unknown as User[]);
      this.fetchingOperators.next(false);
    });

    return this.listOperators.asObservable();
  }

  getListClients(): Observable<Client[] | null> {
    if (this.fetchingClients.value) {
      return this.listClients.asObservable();
    }
    if (this.listClients.value) {
      return this.listClients.asObservable();
    }
    
    this.fetchingClients.next(true);
    let url = `${environment.backendUrl}/${Const.APIURI_LIST_CLIENTS}`;
    const res = this.api.GET(url);
    res.subscribe(response => {
      this.listClients.next(response.data?.list_data as unknown as Client[]);
      this.fetchingClients.next(false);
    });

    return this.listClients.asObservable();
  }

  public async assignTaskToUser(taskId, userId) {
    if (!taskId) UIHelper.showErr(`taskId is missing`);
    if (!userId) UIHelper.showErr(`userId is missing`);
    
    let url = `${environment.backendUrl}/${Const.API_ASSIGN_TASK(taskId)}`;
    let params = { userId: userId }
    const [err, resp] = await to(lastValueFrom(this.api.POST(url, params)));
    if (err) {
      UIHelper.showErr(err);
    }
  }

  
}